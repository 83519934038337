import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { MediaType } from "../enums/mediatype.enum";
import { MediaModel } from "../models/media.model";
import { PodCastService } from "../services/podcast.service";
import { SharedService } from "../services/shared.service";
import { YoutubeService } from "../services/youtube.service";
import { faHeadphonesAlt, faBook } from "@fortawesome/free-solid-svg-icons";
import { NgxSpinnerService } from "ngx-spinner";
import { TestiService } from "../services/testi.service";
import { WPblogModel } from "../models/wp-blog.model";
import { forkJoin, Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-videopodcast",
  templateUrl: "./videopodcast.component.html",
  styleUrls: ["./videopodcast.component.scss"],
})
export class VideopodcastComponent implements OnInit {
  @Input() datiIngresso: MediaModel;

  videos: any;
  podcast: any;
  listaVideoCanale: any[] = [];
  iconaAscolta = faHeadphonesAlt;
  iconaLeggi = faBook;
  constructor(
    private youtubeServ: YoutubeService,
    private podCastServ: PodCastService,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private testi: TestiService,
    private cdr: ChangeDetectorRef,
    private route: Router
  ) {}

  ngOnInit() {
    if (this.datiIngresso != undefined) {
      if (this.datiIngresso.type === MediaType.PODCAST) {
        this.getPodcastAudio();
      } else if (this.datiIngresso.type === MediaType.YOUTUBE) {
        this.getYoutubeVideos();
      } else if (this.datiIngresso.type === MediaType.TESTI) {
        this.getAllTestiFromSandra();
      }
    }
  }

  private getYoutubeVideos() {
    if (this.listaVideoCanale.length === 0) {
      this.youtubeServ.getVideosFromInvidiousApi(30).subscribe((result) => {
        this.videos = result;
        this.listaVideoCanale = this.videos.relatedStreams;
        this.sharedService.videocast = this.listaVideoCanale;
        this.sharedService.videoCastOriginale = [];
        result.relatedStreams.map((elem) => {
          this.sharedService.videoCastOriginale.push(Object.assign({}, elem));
        });
        //console.log('Lista Video ',result);
      });
    }
  }

  private getPodcastAudio() {
    this.sharedService.loadAndHideSpinner();
    this.podCastServ.getListOfPodcast().subscribe((result) => {
      this.podcast = result;
      this.sharedService.podcast = this.podcast;
      this.sharedService.podcastOriginale = Object.assign({}, result);
      //console.log('Lista PodCast ',result);
    });
  }

  private getAllTestiFromSandra() {
    const postSandra: Observable<WPblogModel[]> = this.testi.getAllTesti();
    const postFede: Observable<any> = this.testi.getAllTestiFede();
    forkJoin(postSandra, postFede).subscribe((result) => {
      const listaFede:WPblogModel[] = this.sharedService.createBlogFromGhostPublishing(result[1]);
      this.sharedService.testi = [...result[0],...listaFede];
      this.sharedService.testiOriginale = [...result[0],...listaFede];
      this.sharedService.testi.sort((a,b)=>a.date > b.date ? -1 : a.date < b.date ? 1 : 0);
    });
  }

  checkMediaType(type: MediaType) {
    if (type === MediaType.YOUTUBE) {
      return "Y";
    } else if (type === MediaType.PODCAST) {
      return "P";
    } else if (type === MediaType.TESTI) {
      return "T";
    }
  }

  callPodCast(urlPodcast: string) {}

  callYoutubeVideo(urlVideo: string) {}

  dettaglio(obj: any, cosa: string) {
    this.sharedService.loadAndHideSpinner();
    this.cdr.detectChanges();
    if (cosa === "P") {
      this.sharedService.resetAllComponents();
      this.sharedService.dettaglio = true;
      this.sharedService.dettaglioPod = obj;
      this.route.navigate(['dettaglio/podcast'])
    } else if (cosa === "V") {
      this.sharedService.resetAllComponents();
      this.sharedService.dettaglio = true;
      this.sharedService.dettaglioVid = obj;
      this.route.navigate(['dettaglio/video'])
    } else if (cosa === "T") {
      this.sharedService.resetAllComponents();
      this.sharedService.dettaglio = true;
      this.sharedService.dettaglioTesto = obj as WPblogModel;
      let titolo: string = this.sharedService.dettaglioTesto.title.rendered.trim().replace(/\s/g, '_');
      this.route.navigateByUrl(
        `/articolo/${this.sharedService.dettaglioTesto.id}/${titolo}`
      );
    }
  }
}
