<div class="row">
  <div class="col-xl-12">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="" (click)="naviga('H')">Home</a></li>
            <li class="breadcrumb-item" *ngIf="shared?.dettaglioPod && shared?.provenienza === 'L'" ><a [routerLink]="" (click)="naviga('LP')" >Lista Podcast</a></li>
            <li class="breadcrumb-item" *ngIf="shared?.dettaglioVid  && shared?.provenienza === 'L'" ><a [routerLink]="" (click)="naviga('LV')">Lista Video Lezioni</a></li>
            <li class="breadcrumb-item" *ngIf="shared?.dettaglioTesto  && shared?.provenienza === 'L'" ><a [routerLink]=""(click)="naviga('LT')">Lista Contenuti</a></li>

            <li class="breadcrumb-item active" *ngIf="shared?.dettaglioPod"  aria-current="page">Dettaglio Podcast</li>
            <li class="breadcrumb-item active" *ngIf="shared?.dettaglioVid"  aria-current="page">Dettaglio Video Lezione</li>
            <li class="breadcrumb-item active" *ngIf="shared?.dettaglioTesto"  aria-current="page">Dettaglio Contenuti</li>
          </ol>
        </nav>
  </div>
</div>
<div class="row" *ngIf="shared?.dettaglioPod">
    <div class="col-xl-12">
        <div class="card w-100" style="width: 18rem;" >
            <!-- <img [src]="pod.thumbnail" class="card-img-top" alt="pod.title"> -->
            <div class="card-body">
                <div class="text-center">
                    <img [src]="shared?.dettaglioPod?.thumbnail" class="img-thumbnail" alt="shared?.dettaglioPod?.title">
                </div>
              <h5 class="card-title" [innerHTML]="shared?.dettaglioPod?.title"></h5>
              <p class="card-text text-justify" [innerHTML]="shared?.dettaglioPod?.description"></p>
            </div>
            <div class="card-action text-center">
                <audio controls>
                    <source src="{{shared?.dettaglioPod?.enclosure?.link}}" type="audio/x-m4a">
                    Your browser does not support the audio tag.
                  </audio>                  
            </div>
            
          </div>            
    </div>
</div>

<div class="row" *ngIf="shared?.dettaglioVid">
    <div class="col-xl-12">
        <div class="card w-100" style="width: 18rem;" >
            <!-- <img [src]="pod.thumbnail" class="card-img-top" alt="pod.title"> -->
            <div class="card-body">
              <div class="embed-responsive embed-responsive-21by9">
              <iframe width="100%" height="315"
                [src]="sanitizeYoutubeUrl(shared?.dettaglioVid?.url)">
                </iframe>
              </div>
            </div>
            <div class="card-action text-left m-3">
              <h5 class="card-title"[innerHTML]="shared?.dettaglioVid?.title"></h5>
              <p class="card-text text-justify" [innerHTML]="shared?.dettaglioVid?.shortDescription"></p>
              <p>Pubblicato: {{shared?.dettaglioVid?.publishedText}}</p>
            </div>
            
          </div>            
    </div>
</div>
<div class="row" *ngIf="shared?.dettaglioTesto">
    <div class="col-xl-12">
        <div class="card w-100" style="width: 18rem;" >
            <img [src]="shared?.dettaglioTesto?.immagine" class="card-img-top" alt="{{shared?.dettaglioTesto?.immagine}}">
            <div class="card-body">
              <h5 class="card-title">{{shared?.dettaglioTesto?.title.rendered}}</h5>
              <p class="card-text text-justify" [innerHTML]="shared?.dettaglioTesto?.content.rendered | sanitizeHtml"></p>
            </div>
            <!-- <div class="card-action text-right">
              <a href="#" class="btn btn-primary mb-2 mr-2">Ascolta</a>
            </div> -->
            
          </div>            
    </div>
</div>